<template>
  <div class="t_course_manage_list">
    <div class="chapter" v-for="(item, index) in data" v-if="course && T0 === course.courseType" :key="index">
      <div class="chapter_hedaer">
        <p class="chapter_name">{{ item.chapterName }}</p>
        <!-- <p class="chapter_percent">
          <i class="el-icon-time"></i>
          <span>学习进度：{{ item.learnProgress }}%</span>
        </p> -->
      </div>
      <el-collapse
        v-model="item.activeName"
        @change="handleChange"
        class="chapter_collapse"
      >
        <el-collapse-item :name="i" v-for="(c, i) in item.childList" :key="i">
          <template slot="title">
            <div class="collapse_header">
              <p>{{ c.chapterName }}</p>
              <!-- <p class="collapse_header_progress">
                <el-progress :percentage="c.learnProgress"></el-progress>
              </p> -->
            </div>
          </template>
          <div class="collapse_content">
            <ul class="collapse_content_ul">
              <li v-for="(d, d_i) in c.childList" :key="d_i">
                <p>
                  <el-tag type="plain" size="small">
                    <span v-if="d.teachingType == 0">视频</span>
                    <span v-if="d.teachingType == 1">图片</span>
                    <span v-if="d.teachingType == 2">在线作业</span>
                    <span v-if="d.teachingType == 3">外部链接</span>
                    <span v-if="d.teachingType == 4">其他</span>
                  </el-tag>
                  <span
                    class="collapse_content_li_name"
                    @click="openCourse(d)"
                    >{{ d.chapterName }}</span
                  >
                </p>
                <!-- <p class="collapse_content_li_progress">
                  <el-progress
                    :percentage="d.learnProgress"
                    :show-text="false"
                  ></el-progress>
                </p> -->
              </li>
            </ul>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>



    <div class="chapter" v-if="course && T0 !== course.courseType" :key="index">
      <el-collapse
          @change="handleChange"
          class="chapter_collapse"
      >
        <el-collapse-item :name="i" v-for="(c, i) in data" :key="i">
          <template slot="title">
            <div class="collapse_header">
              <p>{{ c.chapterName }}</p>
              <!-- <p class="collapse_header_progress">
                <el-progress :percentage="c.learnProgress"></el-progress>
              </p> -->
            </div>
          </template>
          <div class="collapse_content">
            <ul class="collapse_content_ul">
              <li v-for="(d, d_i) in c.childList" :key="d_i">
                <p>
                  <el-tag type="plain" size="small">
                    <span v-if="d.teachingType == 0">视频</span>
                    <span v-if="d.teachingType == 1">图片</span>
                    <span v-if="d.teachingType == 2">在线作业</span>
                    <span v-if="d.teachingType == 3">外部链接</span>
                    <span v-if="d.teachingType == 4">其他</span>
                  </el-tag>
                  <span
                      class="collapse_content_li_name"
                      @click="openCourse(d)"
                  >{{ d.chapterName }}</span
                  >
                </p>
                <!-- <p class="collapse_content_li_progress">
                  <el-progress
                    :percentage="d.learnProgress"
                    :show-text="false"
                  ></el-progress>
                </p> -->
              </li>
            </ul>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>


    <el-dialog :visible.sync="dialog" title="预览资源">
      <!-- 视频 / 其他 -->
      <InstantVideo
        v-if="select.teachingType === 0"
        :resourceUrl="select.teachingUrl"
      />
      <!-- 图片 -->
      <el-image
        style="width: 100%; height: 100%"
        v-else-if="select.teachingType === 1"
        :src="select.teachingUrl"
      ></el-image>
      <!-- 外部链接 -->
      <el-link
        type="primary"
        target="_blank"
        v-else-if="select.teachingType === 3"
        :href="select.teachingUrl"
        >查看</el-link
      >
      <template v-if="select.teachingType === 4">
        <!-- 其他-视频 -->
        <InstantVideo
          v-if="
            select.teachingUrl.substr(
              select.teachingUrl.length - 4,
              select.teachingUrl.length
            ) === 'mp4'
          "
          :resourceUrl="select.teachingUrl"
        />
        <!-- 其他-图片 -->
        <el-image
          style="width: 100%; height: 100%"
          v-else-if="
            '.png.jpg.jpeg.gif.JPG.PNG.JPEG'.includes(
              select.teachingUrl.substr(
                select.teachingUrl.length - 4,
                select.teachingUrl.length
              )
            )
          "
          :src="select.teachingUrl"
        ></el-image>
        <el-link
          type="primary"
          target="_blank"
          v-else
          :href="select.teachingUrl"
          >查看</el-link
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import InstantVideo from "./InstantVideo.vue";
import { COURSE_TYPE } from "@/tools/const";
export default {
  name: "t_course_manage_list",
  components: { InstantVideo },
  props: {
    data: Array,
  },
  data() {
    return {
      dialog: false,
      select: {
        teachingType: null,
        teachingUrl: null,
      },
      activeNames: "1",
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    T0() {
      return Number(COURSE_TYPE.T0);
    },
  },
  methods: {
    handleChange(val) {},
    openCourse(item) {
      if (item.teachingType === 2) {
        this.$router.push("/teacher/t/work/online/detail");
        return;
      }
      this.dialog = true;
      this.select = {
        teachingType: null,
        teachingUrl: null,
      };
      this.select = item;
      return;
      let routeData = this.$router.resolve({
        name: "cours-student-study-details",
        // path: "/cours/student/study/details",
        query: { id: item.id, t: 1 },
        params: { data: this.data, item: item },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.t_course_manage_list {
  .chapter {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #eee;

    .chapter_hedaer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .chapter_name {
        font-size: 18px;
        padding-left: 10px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 25%;
          height: 13px;
          width: 3px;
          border-radius: 2px;
          background: @mainColor;
        }
      }

      .chapter_percent {
        .el-icon-time {
          margin-right: 5px;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.t_course_manage_list {
  .chapter {
    .chapter_collapse {
      border-top: 0;

      .el-collapse-item {
        margin-top: 20px;

        .el-collapse-item__content {
          padding-bottom: 10px;
        }
      }

      .el-collapse-item__wrap {
        border-bottom: 0;
      }

      .el-collapse-item__header {
        background: #f1f6ff;
        border-bottom: 0;
        position: relative;

        .el-collapse-item__arrow {
          position: absolute;
          left: 10px;
          top: 18px;
        }

        .collapse_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-left: 35px;
          font-size: 16px;

          .collapse_header_progress {
            width: 200px;
          }
        }
      }

      .collapse_content {
        .collapse_content_ul {
          padding: 0 30px;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding: 15px 0;

            .collapse_content_li_progress {
              width: 100px;
            }

            .collapse_content_li_name {
              display: inline-block;
              margin-left: 10px;
              color: #717883;
              cursor: pointer;

              &:hover {
                color: @mainColor;
              }
            }
          }
        }
      }
    }
  }
}
</style>
