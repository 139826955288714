
<style lang="less" scoped>
.t_course_manage {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
  }
}
</style>

<template>
  <div class="t_course_manage">
    <div class="stu-module-header">
      <div class="stu-module-title">课程管理</div>
      <el-button icon="el-icon-edit" type="primary" @click="edit"
        >编辑</el-button
      >
    </div>
    <div class="content">
      <CourseList :data="data" />
    </div>
  </div>
</template>

<script>
import CourseList from "./module/CourseList.vue";
export default {
  name: "t_course_manage",
  components: { CourseList },
  data() {
    return {
      data: [],
      courseInfo: {
        coverUrl:
          "http://hnscen.oss-cn-beijing.aliyuncs.com/hklmCarouselImage/2021-04-27/afb04cb07e154cf39b38ef7a38736e04蒲公英.jpeg",
        courseName: "计算机基础1",
        courseDesc: "基础必须得会啊",
        teacher: " 候大佐",
        courseDuration: 0.2,
        semester: 2,
        learnProgress: 0.0,
      },
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    }
  },
  activated() {
    this.query();
  },
  methods: {
    query(){
      let formData = new FormData();
      formData.append("courseId",this.course.id);
      this.$axios_supermallData.post("/hnjxjy-core/eduCourseOutline/list",formData).then(resp =>{
        if (resp.data.code == 2000){
          this.data = resp.data.data;
        }
      })
    },
    edit() {
      this.$router.push("manage/edit");
    },
  },
};
</script>
